<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 class="pb-2">اضافة ملف للحصة</h5>

                       <span> يرجى ملئ جميع الخانات </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form class="needs-validation" autocomplete="off" 
                    [formGroup] = "coursecontentpdf"
                    (ngSubmit)="onSubmit()" 
                    novalidate>
                        <div class="form-row">

                             <!-- Student Name  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> اسم الملف</label>
                                <input class="form-control" id="validation01" type="text" formControlName="namePdf"
                                [tooltip]="fc.namePdf.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.namePdf.valid && (fc.namePdf.touched)"
                                [class.is-invalid]="fc.namePdf.invalid && (fc.namePdf.touched)"
                                
                                     required
                                     placeholder="اكتب الملف"
                                    containerClass=""/>
                            </div>
                            <!-- visibility -->
                            <div class="col-lg-6 col-sm-12  mb-3">
                                <label for="validationCustom05"><span class="validation-required">*</span> اختر امكانية التحميل</label>
                                <ng-select  [selectOnTab]="true" formControlName="flag"
                                    class="form-control p-0" placeholder="اختر امكانية التحميل"
                                    containerClass="" 
                                    id="validationCustom05">
                                    <ng-option *ngFor="let item of visilblity" [value]="item.id">{{item.title}}</ng-option>
                                </ng-select>
                            </div> 
                        <!-- to insert image for the student  -->
              <div class="col-lg-6 my-4" style="padding: 1%;">
                <img  src="{{ Logopdf }}" style="width: 60%; height: 220px;" class="image-style p-2" *ngIf="Logopdf != null"/>
               <input type="file" accept="file/pdf, file/PDF" #file id="getLogo" formControlName="pdf"
                style="display: none" (change)="getLogoUrl($event)" />
            <button type="button" onclick="document.getElementById('getLogo').click()"
                class="btn  d-block"  style="width: 60%;">
                    ارفاق PDF للمحتوى   
            </button>    
                        </div>
                        </div>

                        <br>
                        <ng-template #elseBlock>
                            <button [disabled]="button" class="btn pull-right" type="submit">
                               حفظ
                            </button>
                            </ng-template>
                        <div *ngIf="update; else elseBlock">
                            <button class="btn pull-right" type="submit">
                                تعديل
                            </button>
                       </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



