<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-3">
                            <h5 class="pb-2">تصنيف محتوى المواد</h5>
                        </div>
                        <div class="col-6">
                            <input 
                            class="form-control form-control-light" 
                            type="text" 
                            (keydown)="dectectBackSlash($event)"
                            (ngModelChange)="searchInStudents($event)"
                            placeholder="ابحث باسم الطالب او بالرقم التسلسلي او البريد الالكتروني"
                            [(ngModel)]="filterstring">
                          </div>
                        <div class="col-3 header-titles">
                            <a routerLink="/content/admin/InsertSubCourseContent" class="d-block">
                                <button class="btn pull-right" type="button">
                                    <span class="m-1">اضافة تصنيف محتوى المواد</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class="">
                                        <th scope="col"></th>
                                        <th scope="col">اسم تصنيف محتوى المادة</th>
                                        <th scope="col">اسم تصنيف المادة</th>
                                        <th scope="col">عدد الحصص</th>
                                        <th scope="col">السعر</th>
                                        
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of subsubjects | paginate : {
                                        itemsPerPage: tableSize,
                                        currentPage: page,
                                        totalItems: count };let x = index" class=" ">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td scope="row" class="p-2">{{view.beforSubjectContentName}}</td>
                                        <td scope="row" class="p-2">{{view.subSubjectName}}</td>
                                        <td scope="row" class="p-2">{{view.subject_content_count}}</td>
                                        <td scope="row" class="p-2">{{view.subject_content_price_sum}}</td>
                                          
                                            <td class="font-style chose">
                                                <button *ngIf="view.beforeFlag == 0" class="btn pull-right"
                                                    style="color: rgba(14, 14, 14, 0.667); padding: 7px"
                                                   (click)="showBeforSubjectContent(view.beforSubjectContentId, view.beforeFlag)" title="اظهار هذا المحتوى">
                                                    <i class="fa fa-eye-slash"></i>
                                                </button>
                                                <button *ngIf="view.beforeFlag == 1" class="btn pull-right"
                                                    style="color: rgba(14, 14, 14, 0.667); padding: 7px"
                                                   (click)="showBeforSubjectContent(view.beforSubjectContentId, view.beforeFlag)" title="اخفاء هذا المحتوى">
                                                    <i class="fa fa-eye"></i>
                                                </button>
                                                <button class="btn pull-right"
                                                    style="color: rgba(14, 14, 14, 0.667); padding: 7px"
                                                    (click)="GettheStudentsInTheSubSubjectContent(view.beforSubjectContentId)" title="الطلبة المسجلين في هذا المحتوى">
                                                    <i class="fa fa-arrow-right"></i>
                                                </button>
                                                <button class="btn pull-right"
                                                    style="color: rgba(234, 220, 25, 0.667); padding: 7px"
                                                    (click)="getallthecourses(view.beforSubjectContentId)" title="عرض جميع حصص هذا التصنيف">
                                                    <i class="fa fa-folder"></i>
                                                </button>
                                                <button class="btn pull-right"
                                                    style="color: rgba(186, 79, 79, 0.667); padding: 7px"
                                                    (click)="rearrangesubsubjectcontent(view.beforSubjectContentId)" title="اعادة ترتيب محتوى المادة في هذا التصنيف">
                                                    <i class="fa fa-spin fa-refresh"></i>
                                                </button>
                                                <button class="btn pull-right"
                                                    style="color: rgba(59, 133, 99, 0.667); padding: 7px"
                                                    (click)="addcontent(view)" title="اضافة محتوى مادة لهذا التصنيف">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                                <button class="btn pull-right"
                                                    style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                    (click)="update(view)" title="تعديل هذا التصنيف">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                                   <button class="btn  pull-right"  style="color: red; padding: 7px"
                                                   (click)="delete(view.beforSubjectContentId)" title="مسح هذا التصنيف">
                                                    <i class="fa fa-trash"></i>
                                                       </button>
                                                
                                            
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <pagination-controls
    previousLabel="السابق"
    nextLabel="التالي"
    (pageChange)="onTableDataChange($event)"
    class="text-center">
    </pagination-controls>
</div>




