import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-question',
  templateUrl: './video-question.component.html',
  styleUrls: ['./video-question.component.css']
})
export class VideoQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
