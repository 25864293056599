<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 class="pb-2">مدرس جديد</h5>

                       <span> يرجى ملئ جميع الخانات </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form class="needs-validation" autocomplete="off" 
                    [formGroup] = "TeacherForm"
                    (ngSubmit)="onSubmit()" 
                    novalidate>
                        <div class="form-row">
                            <!-- Teacher Name  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> اسم المدرس</label>
                                <input class="form-control" id="validation01" type="text" formControlName="teacherName"
                                [tooltip]="fc.teacherName.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.teacherName.valid && (fc.teacherName.touched)"
                                [class.is-invalid]="fc.teacherName.invalid && (fc.teacherName.touched)"
                                     required
                                     placeholder="الأسم"
                                    containerClass="" />
                            </div>
                            <!-- Email  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> البريد الالكتروني</label>
                                <input class="form-control" id="validation01" type="text" formControlName="email"
                                [tooltip]="fc.email.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.email.valid && (fc.email.touched)"
                                [class.is-invalid]="fc.email.invalid && (fc.email.touched)"
                                     required
                                     placeholder="البريد الالكتروني"/>
                            </div>
                            <!-- password  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> كلمة المرور</label>
                                <input class="form-control" id="validation01" type="text" formControlName="password"
                                [tooltip]="fc.password.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.password.valid && (fc.password.touched)"
                                [class.is-invalid]="fc.password.invalid && (fc.password.touched)"
                                     required
                                     placeholder="مثال (Ne_w12@34)"/>
                            </div>
                            
                                                                     <!-- select subjects -->
                     <div class="col-lg-6 col-sm-12  mb-3">
                        <label for="validationCustom06"><span class="validation-required">*</span> المرحلة الدراسية</label>
                        <ng-multiselect-dropdown
                               formControlName="educationIds"
                               [placeholder]="'اختر من القائمة'"
                               [settings]="dropdownSettings"
                               [data]="educationlevels"
                               [(ngModel)]="selectedItems">
                        </ng-multiselect-dropdown>
                    </div>
                             <!-- choose subject  -->
                             <div class="col-lg-6 col-sm-12  mb-3">
                                <label for="validationCustom05"><span class="validation-required">*</span> المادة</label>
                                <ng-select  [selectOnTab]="true" formControlName="subjectId"
                                    class="form-control p-0" placeholder="اختر المادة "
                                    containerClass="" 
                                    id="validationCustom05">
                                    <ng-option *ngFor="let item of subjects" [value]="item.subjectId">{{item.subjectName}}</ng-option>
                                </ng-select>
                            </div>
                              <!-- Gender  -->
                              <div class="col-lg-6 col-sm-12  mb-3">
                                <label for="validationCustom05"><span class="validation-required">*</span> النوع</label>
                                <ng-select  [selectOnTab]="true" formControlName="gender"
                                    class="form-control p-0" placeholder="اختر النوع "
                                    containerClass="" 
                                    id="validationCustom05">
                                    <ng-option *ngFor="let item of genders" [value]="item.name">{{item.name}}</ng-option>
                                </ng-select>
                            </div>

                             <!-- Location  -->
                             <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> عنوان السكن</label>
                                <input class="form-control" id="validation01" type="text" formControlName="location"
                                [tooltip]="fc.location.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.location.valid && (fc.location.touched)"
                                [class.is-invalid]="fc.location.invalid && (fc.location.touched)"
                                     required
                                     placeholder="العنوان" />
                            </div>
                            <div class="col-6 my-3 " >
                                <img  src="{{ imageLogo }}" style="width: 60%; height: 220px;" class="image-style p-2" *ngIf="imageLogo != null"/>
                               <input type="file" formControlName="studentImage" accept="image/*" #file id="getLogo"
                                style="display: none" (change)="getLogoUrl($event)" />
                            <button type="button" onclick="document.getElementById('getLogo').click()"
                                class="btn  d-block"  style="width: 60%;">
                                 صورة شخصية للمدرس
                            </button>    
                                        </div>
                           
                        </div>

                        <br>
                        <ng-template #elseBlock>
                            <button [disabled]="button" class="btn pull-right" type="submit">
                               حفظ
                            </button>
                            </ng-template>
                        <div *ngIf="update; else elseBlock">
                            <button class="btn pull-right" type="submit">
                                تعديل
                            </button>
                       </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



