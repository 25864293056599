<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 class="pb-2">تغيير كلمة السر</h5>

                       <span> يرجى ملئ جميع الخانات </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form class="needs-validation" autocomplete="off" 
                    [formGroup] = "ChangepasswordForm"
                    (ngSubmit)="onSubmit()" 
                    novalidate>
                        <div class="form-row">
                            <!-- oldpassword  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span>  كلمة السر القديمة</label>
                                <input class="form-control" id="validation01" type="text" formControlName="oldpassword"
                                [tooltip]="fc.oldpassword.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.oldpassword.valid && (fc.oldpassword.touched)"
                                [class.is-invalid]="fc.oldpassword.invalid && (fc.oldpassword.touched)"
                                onlyNumbers
                                     required
                                     placeholder="ادخل كلمة السر القديمة"
                                    containerClass="" />
                            </div>
                            <!-- newpassword  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> كلمة السر الجديدة</label>
                                <input class="form-control" id="validation01" type="text" formControlName="newpassword"
                                [tooltip]="fc.newpassword.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.newpassword.valid && (fc.newpassword.touched)"
                                [class.is-invalid]="fc.newpassword.invalid && (fc.newpassword.touched)"
                                onlyNumbers
                                     required
                                     placeholder="ادخل كلمة السر الجديدة"
                                    containerClass="" />
                            </div>
                            <!-- repeatnewpassword  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> اعد كتابة كلمة السر الجديدة</label>
                                <input class="form-control" id="validation01" type="text"
                                onlyNumbers
                                     required
                                     placeholder="اعد كتابة كلمة السر الجديدة"
                                    containerClass="" />
                            </div>
                          
              
                           
                        </div>

                        <br>
                            <button [disabled]="button" class="btn pull-right" type="submit">
                               حفظ كلمة السر الجديدة
                            </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



