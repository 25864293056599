
export const environment = {
  production: false
 
};

export const Server_URL='https://www.maapp.misrpedia.com/public/api'
// export const Server_URL='https://elmister.eyedebugger.com/public/api'


