<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">قائمة الملفات الخاصة بهذة الحصة</h5>               
                        </div>
                        <div class="col-6 header-titles">
                            <a routerLink="/content/admin/InsertCoursesPdf" class="d-block">
                                <button class="btn pull-right" type="button">
                                    <span class="m-2">اضافة ملف جديد</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class=" ">
                                        <th scope="col"></th>
                                        <th scope="col">اسم الملف</th>
                                        <th scope="col">ملفات الخاصة بالحصص</th>
                                        <th scope="col">امكانية تحميل الملف</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of pdfs; let x = index" class=" ">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td scope="row" class="p-2">{{view.namePdf}}</td>
                                        <td scope="row" class="p-2"><a target="_blank" [href]='baseURL + view.pdfs'>{{view.pdfs}}</a></td>
                                        <td scope="row" class="p-2">
                                            <span *ngIf="view.flag == 1"
                                            style="color: rgba(79, 200, 68, 0.667); padding: 7px;cursor:pointer;"
                                            title="تعديل امكانية تحميل الطالب للملف"
                                            (click)="update(view)">
                                            <i class="fa fa-check"></i>
                                        </span>
                                            <span *ngIf="view.flag == 0"
                                            style="color: red; padding: 7px; cursor:pointer;"
                                            title="تعديل امكانية تحميل الطالب للملف" 
                                            (click)="update(view)">
                                            <i class="icon-close"></i>
                                                 </span>

                                        </td>
                                                                          
                                            <td class="font-style chose">
                                                <!-- <button class="btn pull-right"
                                                    style="color: rgba(200, 110, 42, 0.667); padding: 7px"
                                                    (click)="update(view)"
                                                    title="تعديل امكانية تحميل الطالب للملف">
                                                    <i class="fa fa-pencil"></i>
                                                </button> -->
                                                   <button class="btn  pull-right"  style="color: red; padding: 7px"
                                                   (click)="delete(view.id)"
                                                   title="حذف هذا الملف">
                                                    <i class="fa fa-trash"></i>
                                                       </button>
                                                
                                            
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




