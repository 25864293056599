<div class="container my-4">
    <form>
        <div class="row">
            <div class="col-md-12 mb-3">
                <label><span class="validation-required">*</span>رأس السؤال</label>
                <angular-editor [config]="editorConfig"></angular-editor>
            </div>
            <div class="col-md-12 mb-3">
                <label><span class="validation-required">*</span>رأس السؤال</label>
                <input class="form-control" type="file" accept="image/*">
            </div>
            <div class="col-md-6 mb-3">
                <label><span class="validation-required">*</span>اختار الاجابة الصحيحة</label>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="true">
                    <label class="form-check-label">
                        True
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [value]="false">
                    <label class="form-check-label">
                        False
                    </label>
                </div>
            </div>
            <div class="col-md-6 p-5">
                <input class="form-check-input" type="checkbox" value="true">
                <label class="form-check-label">
                    <h5>اضافة تعليل لاجابته</h5>
                </label>
            </div>
            <div class="col-md-12">
                <button class="btn" type="submit">
                    حفظ
                </button>
            </div>
        </div>
    </form>
</div>