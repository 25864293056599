<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 class="pb-2">كورس جديد</h5>

                       <span> يرجى ملئ جميع الخانات </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form class="needs-validation" autocomplete="off" 
                    [formGroup] = "CourseForm"
                    (ngSubmit)="onSubmit()" 
                    novalidate>
                        <div class="form-row">
                            <!-- subjectName  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> اسم الكورس</label>
                                <input class="form-control" id="validation01" type="text" formControlName="subjectName"
                                [tooltip]="fc.subjectName.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.subjectName.valid && (fc.subjectName.touched)"
                                [class.is-invalid]="fc.subjectName.invalid && (fc.subjectName.touched)"
                                     required
                                     placeholder="اسم الكورس"/>
                            </div>
                            <!-- description  -->
                            <div class="col-6 mb-3">
                                <label for="validation01"><span class="validation-required">*</span> تفاصيل الكورس</label>
                                <input class="form-control" id="validation01" type="text" formControlName="description"
                                [tooltip]="fc.description.errors?.required ? 'الحقل مطلوب' : null"
                                [class.is-valid]="fc.description.valid && (fc.description.touched)"
                                [class.is-invalid]="fc.description.invalid && (fc.description.touched)"
                                     required
                                     placeholder="تفاصيل الكورس"/>
                            </div>
                              <!-- Education Level  -->
                              <div class="col-lg-6 col-sm-12  mb-3">
                                <label for="validationCustom05"><span class="validation-required">*</span> المرحلة الدراسية</label>
                                <ng-select  [selectOnTab]="true" formControlName="educationId"
                                    class="form-control p-0" placeholder="اختر المادة "
                                    containerClass="" 
                                    id="validationCustom05">
                                    <ng-option *ngFor="let item of educationlevels" [value]="item.educationId">{{item.nameAr}}</ng-option>
                                </ng-select>
                            </div>
                                <!-- to insert image for the student  -->
              <div class="col-lg-6 my-4" style="padding: 1%;">
                <img  src="{{ imageLogo }}" style="width: 60%; height: 220px;" class="image-style p-2" *ngIf="imageLogo != null"/>
               <input type="file" accept="image/*" #file id="getLogo"
                style="display: none" (change)="getLogoUrl($event)" />
            <button type="button" onclick="document.getElementById('getLogo').click()"
                class="btn  d-block"  style="width: 60%;">
                    ارفاق صورة للكورس   
            </button>    
                        </div>
                        </div>
                        <br>
                        <ng-template #elseBlock>
                            <button [disabled]="button" class="btn pull-right" type="submit">
                               حفظ
                            </button>
                            </ng-template>
                        <div *ngIf="update; else elseBlock">
                            <button class="btn pull-right" type="submit">
                                تعديل
                            </button>
                       </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>



