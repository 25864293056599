<div class="container my-4">
    <form>
        <div class="row">
            <div class="col-md-12 mb-3">
                <label><span class="validation-required">*</span>رأس السؤال</label>
                <angular-editor [config]="editorConfig"></angular-editor>
            </div>
            <div class="col-md-12 mb-3">
                <label><span class="validation-required">*</span>رأس السؤال</label>
                <input class="form-control" type="file" accept="image/*">
            </div>
            <div class="col-md-12">
                <button class="btn" type="submit">
                    حفظ
                </button>
            </div>
        </div>
    </form>
</div>