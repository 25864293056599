<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">قائمة العروض الخاصة بأبواب المواد</h5>
                        </div>
                        <div class="col-6 header-titles">
                            <a routerLink="/content/admin/InsertOffer" class="d-block">
                                <button class="btn pull-right" type="button">
                                    <span class="m-2">اضافة عرض</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">اسم العرض</th>
                                        <th scope="col">العرض من</th>
                                        <th scope="col">العرض الى</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of Offers;let x = index">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td scope="row" class="p-2">{{view.name}}</td>
                                        <td scope="row" class="p-2">{{view.date_start}}</td>
                                        <td scope="row" class="p-2">{{view.date_end}}</td>
                                        <td class="font-style chose">
                                                <button class="btn pull-right"
                                                    style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                    (click)="update(view)">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            <button class="btn  pull-right"  style="color: red; padding: 7px" (click)="delete(view.offersId)">
                                                    <i class="fa fa-trash"></i>
                                                    </button>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              <!-- ####################################################################################################### -->
              <!-- ####################################################################################################### -->
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">قائمة العروض الخاصة بالحصص</h5>
                        </div>
                        <div class="col-6 header-titles">
                            
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">اسم العرض</th>
                                        <th scope="col">العرض من</th>
                                        <th scope="col">العرض الى</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of SubjectOffers;let x = index">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td scope="row" class="p-2">{{view.name}}</td>
                                        <td scope="row" class="p-2">{{view.date_start}}</td>
                                        <td scope="row" class="p-2">{{view.date_end}}</td>
                                        <td class="font-style chose">
                                                <button class="btn pull-right"
                                                    style="color: #2376f1aa; padding: 7px"
                                                    (click)="updateLesson(view)">
                                                    <i class="fa fa-pencil"></i>
                                                </button>
                                            <button class="btn  pull-right"  style="color: red; padding: 7px" (click)="deleteLesson(view.offersId)">
                                                    <i class="fa fa-trash"></i>
                                                    </button>
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</div>




